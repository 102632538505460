.about {
  max-width: 1140px;
  padding: 4rem 6rem;
  margin-left: auto;
  margin-right: auto;
}

.about__profilePicture {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
}

.about__profilePicture > img {
  object-fit: fill;
  height: 350px;
}
