.header {
  max-width: 1140px;
  padding-top: 4rem;
  padding-left: 6rem;
  padding-right: 6rem;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.header__banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header__nav {
  margin: 0 0 10px;
  display: flex;
}

.header__nav div a {
  color: #999999;
  transition: color 0.2s;
}

.header__nav div a:hover {
  color: black !important;
}

.header__nav .active {
  color: black !important;
}

.header__nav--dark {
  margin: 0 0 10px;
  display: flex;
}

.header__nav--dark div a {
  color: #999999;
  transition: color 0.2s;
}

.header__nav--dark div a:hover {
  color: white !important;
}

.header__nav--dark .active {
  color: white !important;
}

.header__navItem {
  font-size: 22px;
  margin-right: 40px;
}

.header__lastNavItem {
  font-size: 22px;
}

.header__nameSub {
  color: #02cf5d;
}

.header__darkModeSwitch {
  display: flex;
  align-items: center;
}
