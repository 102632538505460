.projectCardSmall {
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  transition: all 150ms ease-in;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  padding: 15px;
}

.projectCardSmall:hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  transform: scale(1.01);
}

.projectCardSmall--dark {
  border-radius: 5px;
  box-shadow: none;
  transition: all 150ms ease-in;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid #333;
  padding: 15px;
}

.projectCardSmall--dark:hover {
  box-shadow: 0 0 0 1px #333;
  transform: scale(1.01);
}
