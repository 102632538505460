.footer {
  padding: 2rem;
}

.footer__title {
  text-align: center;
}

.footer__socials,
.footer__socials--dark {
  margin: 10px;
  padding-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer__socials > a > .MuiSvgIcon-root,
.footer__socials--dark > a > .MuiSvgIcon-root {
  color: #666;
  transition: color 0.2s;
}

.footer__socials > a > .MuiSvgIcon-root:hover {
  color: black;
}

.footer__socials--dark > a > .MuiSvgIcon-root:hover {
  color: white;
}

.footer__copyright {
  font-size: 14px;
  text-align: center;
}
